import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto'

export default class extends Controller {
  static targets = ['lineChart']
  static values = {
    labels: String,
    data: String,
    datasetLabel: String,
    lineColor: String
   }

  canvasContext() {
    return this.lineChartTarget.getContext('2d');
  }

  connect() {
    new Chart(this.canvasContext(), {
      type: 'line',
      data: {
        labels: JSON.parse(this.labelsValue),
        datasets: [{
          label: this.datasetLabelValue,
          data: JSON.parse(this.dataValue),
          fill: false,
          borderColor: this.lineColorValue,
          tension: 0.1
        }]
      },
    });
  }
}
